import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const DatasetsSplit = Loadable(lazy(() => import('./DatasetsSplit')));

const datasetsSplitRoutes = [
  { path: '/datasets/split', element: <DatasetsSplit />, auth: authRoles.admin },
];

export default datasetsSplitRoutes;
