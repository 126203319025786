import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AdvancedHybrid = Loadable(lazy(() => import('./AdvancedHybrid')));

const advancedHybridRoutes = [
  { path: '/advanced/hybrid', element: <AdvancedHybrid />, auth: authRoles.admin },
];

export default advancedHybridRoutes;
