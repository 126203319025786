export const navigations = [
  { label: 'SERVERS', type: 'label' },
  {
    name: 'SERVERS',
    icon: 'visibility',
    children: [
      { name: 'Usage', path: '/servers/usage' },
      { name: 'Status', path: '/servers/status' },
      { name: 'Settings', path: '/servers/settings' },
    ],
  },
  { label: 'RECIPE', type: 'label' },
  {
    name: 'RECIPE',
    icon: 'library_books',
    children: [{ name: 'Editor', path: '/recipe/editor' }],
  },
  { label: 'DATASETS', type: 'label' },
  {
    name: 'DATASETS',
    icon: 'storage',
    children: [
      { name: 'Split', path: '/datasets/split' },
      { name: 'Generate', path: '/datasets/gen' },
    ],
  },

  { label: 'AIV ATHENA', type: 'label' },
  { name: 'Train', path: '/aivAthena/train', icon: 'apple' },
  { name: 'Test', path: '/aivAthena/test', icon: 'adb' },
  { name: 'History', path: '/athena/db/projects', icon: "insights" },
  {
    name: 'Deploy',
    icon: 'thumbs_up_down',
    children: [
      { name: 'Model Analyzer', path: '/aivAthena/deploy/modelAnalyzer' },
      { name: 'Senario 2', path: '/aivAthena/deploy/modelAnalyzer' },
    ],
  },
  {
    name: 'OPEN SOURCE',
    icon: 'airplay',
    children: [
      { name: 'Train', path: '/aivOpenSrc/train' },
      // { name: 'Test', path: '/aivOpenSrc/test' },
      // { name: 'DB', path: '/athena/db/projects' },
      // { name: 'Export', path: '/nexus/export' },
    ],
  },
  {
    name: 'ATHENA',
    icon: 'airplay',
    children: [
      { name: 'Train', path: '/athena/train' },
      { name: 'Test', path: '/athena/test' },
      // { name: 'DB', path: '/athena/db/projects' },
      // { name: 'Export', path: '/nexus/export' },
    ],
  },
  // {
  //   name: 'NEXUS',
  //   icon: 'change_history',
  //   children: [
  //     { name: 'Train', path: '/nexus/train' },
  //     { name: 'Test', path: '/nexus/test' },
  //     { name: 'Export', path: '/nexus/export' },
  //   ],
  // },
  // {
  //   name: 'Athens',
  //   icon: 'account_balance',
  //   children: [
  //     { name: 'Train', path: '/athens/train' },
  //     { name: 'Test', path: '/athens/test' },
  //     { name: 'Export1', path: '/athens/export1' },
  //     { name: 'Export2', path: '/athens/export2' },
  //   ],
  // },
  // {
  //   name: 'Advanced',
  //   icon: 'lock_outline',
  //   children: [
  //     { name: 'Hybrid Train', path: '/advanced/hybrid' },
  //     { name: 'Pipeline', path: '/advanced/pipeline' },
  //   ],
  // },
  {
    name: 'Benchmark',
    icon: 'trending_up',
    children: [
      { name: 'DB', path: '/benchmark/db' },
      { name: 'CSV', path: '/benchmark/csv' },
    ],
  },

  { label: 'Etc.', type: 'label' },
  { name: 'Users', path: '/etc/users', icon: 'group' },
  { name: 'Settings', path: '/etc/settings', icon: 'brightness_high' },

  { label: 'PAGES', type: 'label' },
  // {
  //   name: 'Session/Auth',
  //   icon: 'security',
  //   children: [
  //     { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
  //     { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
  //     { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
  //     { name: 'Error', iconText: '404', path: '/session/404' },
  //   ],
  // },
  // { label: 'Components', type: 'label' },
  // {
  //   name: 'Components',
  //   icon: 'favorite',
  //   badge: { value: '30+', color: 'secondary' },
  //   children: [
  //     { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
  //     { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
  //     { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
  //     { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
  //     { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
  //     { name: 'Form', path: '/material/form', iconText: 'F' },
  //     { name: 'Icons', path: '/material/icons', iconText: 'I' },
  //     { name: 'Menu', path: '/material/menu', iconText: 'M' },
  //     { name: 'Progress', path: '/material/progress', iconText: 'P' },
  //     { name: 'Radio', path: '/material/radio', iconText: 'R' },
  //     { name: 'Switch', path: '/material/switch', iconText: 'S' },
  //     { name: 'Slider', path: '/material/slider', iconText: 'S' },
  //     { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
  //     { name: 'Table', path: '/material/table', iconText: 'T' },
  //   ],
  // },
  // {
  //   name: 'Charts',
  //   icon: 'trending_up',
  //   children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  // },
  // {
  //   name: 'matx docs',
  //   icon: 'launch',
  //   type: 'extLink',
  //   path: 'http://demos.ui-lib.com/matx-react-doc/',
  // },
  {
    name: 'Documentation',
    icon: 'launch',
    type: 'extLink',
    path: 'https://resisted-harmony-eed.notion.site/Athena-e864a2ea7adb44f0933b9b93425c836d',
  },
  {
    name: 'Storage',
    icon: 'launch',
    type: 'extLink',
    path: 'http://192.168.10.41:8082/apps/files/extstoragemounts',
  },
  {
    name: 'Augmentation Demo',
    icon: 'launch',
    type: 'extLink',
    path: 'https://demo.albumentations.ai/',
  },
];
