import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AivAthenaTrain = Loadable(lazy(() => import('./aivAthenaTrain')));

const aivAthenaTrainRoutes = [
  { path: '/aivAthena/train', element: <AivAthenaTrain />, auth: authRoles.admin },
];

export default aivAthenaTrainRoutes;
