import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AivOpenSrcTrain = Loadable(lazy(() => import('./aivOpenSrcTrain')));

const aivOpenSrcTrainRoutes = [
  { path: '/aivOpenSrc/train', element: <AivOpenSrcTrain />, auth: authRoles.admin },
];

export default aivOpenSrcTrainRoutes;
