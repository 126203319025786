import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ServerSettings = Loadable(lazy(() => import('./ServerSettings')));

const serverSettingsRoutes = [
  { path: '/servers/settings', element: <ServerSettings />, auth: authRoles.admin },
];

export default serverSettingsRoutes;
