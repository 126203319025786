import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const DatasetsGen = Loadable(lazy(() => import('./DatasetsGen')));

const datasetsGenRoutes = [
  { path: '/datasets/gen', element: <DatasetsGen />, auth: authRoles.admin },
];

export default datasetsGenRoutes;
