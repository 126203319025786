import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AthensTrain = Loadable(lazy(() => import('./AthensTrain')));

const athensTrainRoutes = [
  { path: '/athens/train', element: <AthensTrain />, auth: authRoles.admin },
];

export default athensTrainRoutes;
