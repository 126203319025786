import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ServerStatus = Loadable(lazy(() => import('./ServerStatus')));

const serverStatusRoutes = [
  { path: '/servers/status', element: <ServerStatus />, auth: authRoles.admin },
];

export default serverStatusRoutes;
