import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

//// Servers
import serverUsageRoutes from 'app/views/serverUsage/ServerUsageRoutes';
import serverStatusRoutes from 'app/views/serverStatus/ServerStatusRoutes';
import serverSettingsRoutes from './views/serverSettings/ServerSettingsRoutes';

//// Recipe Editer
import recipeEditerRoutes from './views/recipeEditor/RecipeEditorRoutes';

//// Datasets
import datasetsSplitRoutes from './views/datasetsSplit/DatasetsSplitRoutes';
import datasetsGenRoutes from './views/datasetsGen/DatasetsGenRoutes';

//// NEXUS
import nexusTestRoutes from './views/nexusTest/NexusTestRoutes';
import nexusTrainRoutes from './views/nexusTrain/NexusTrainRoutes';
import nexusExportRoutes from './views/nexusExport/NexusTestRoutes';

//// ATHENA
import athenaTrainRoutes from './views/athenaTrain/AthenaTrainRoutes';
import athenaDbProjectsRoutes from './views/athenaDb/AthenaDbRoutes';
import athenaTestRoutes from './views/athenaTest/AthenaTestRoutes';
import NewTabForExplainParameters from './views/athenaTrain/shared/newTabForExplainParameters';

//// AIVATHENA
import aivAthenaTrainRoutes from './views/aivAthenaTrain/aivAthenaTrainRoutes';
import aivAthenaTestRoutes from './views/aivAthenaTest/aivAthenaTestRoutes';
import aivAthenaDeployRoutes from './views/aivAthenaDeploy/aivAthenaDeployRoutes';

//// AIVOPENSRC
import aivOpenSrcTrainRoutes from './views/aivOpenSrcTrain/aivOpenSrcTrainRoutes';

//// ATHENS
import athensTrainRoutes from './views/athensTrain/AthensTrainRoutes';
import athensTestRoutes from './views/athensTest/AthensTestRoutes';
import athensExportRoutes1 from './views/athensExport1/AthensExportRoutes';
import athensExportRoutes2 from './views/athensExport2/AthensExportRoutes';

//// Advanced
import advancedHybridRoutes from './views/advancedHybrid/AdvancedHybridRoutes';
import advancedPipelineRoutes from './views/advancedPipeline/AdvancedPipelineRoutes';

//// Benchmark
import benchmarkCsvRoutes from './views/benchmarkCsv/BenchmarkCsvRoutes';
import benchmarkDBRoutes from './views/benchmarkDB/BenchmarkDBRoutes';

//// Etc.

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...serverUsageRoutes,
      ...serverStatusRoutes,
      ...serverSettingsRoutes,
      ...recipeEditerRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...datasetsSplitRoutes,
      ...datasetsGenRoutes,
      ...nexusTestRoutes,
      ...nexusTrainRoutes,
      ...nexusExportRoutes,
      ...athensTestRoutes,
      ...athensExportRoutes1,
      ...athensExportRoutes2,
      ...athenaTrainRoutes,
      ...athenaDbProjectsRoutes,
      ...aivAthenaTrainRoutes,
      ...aivAthenaTestRoutes,
      ...aivAthenaDeployRoutes,
      ...aivOpenSrcTrainRoutes,
      ...athensTrainRoutes,
      ...athenaTestRoutes,
      ...advancedHybridRoutes,
      ...advancedPipelineRoutes,
      ...benchmarkDBRoutes,
      ...benchmarkCsvRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="servers/usage" /> },
  { path: '*', element: <NotFound /> },
  { path: '/explainParametersTab', element: <NewTabForExplainParameters />}
];

export default routes;
