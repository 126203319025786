import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ServerUsage = Loadable(lazy(() => import('./ServerUsage')));

const serverUsageRoutes = [
  { path: '/servers/usage', element: <ServerUsage />, auth: authRoles.admin },
];

export default serverUsageRoutes;
