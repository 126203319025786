import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const NexusExport = Loadable(lazy(() => import('./NexusExport')));

const nexusExportRoutes = [
  { path: '/nexus/export', element: <NexusExport />, auth: authRoles.admin },
];

export default nexusExportRoutes;
