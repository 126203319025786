import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AthenaTrain = Loadable(lazy(() => import('./AthenaTrain')));

const athenaTrainRoutes = [
  { path: '/athena/train', element: <AthenaTrain />, auth: authRoles.admin },
];

export default athenaTrainRoutes;
