import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AthensExport = Loadable(lazy(() => import('./AthensExport')));

const athensExportRoutes2 = [
  { path: '/athens/export2', element: <AthensExport />, auth: authRoles.admin },
];

export default athensExportRoutes2;
