import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AivAthenaDeploy = Loadable(lazy(() => import('./aivAthenaDeploy')));

const aivAthenaDeployRoutes = [
    { path: '/aivAthena/deploy/modelAnalyzer', element: <AivAthenaDeploy />, auth: authRoles.admin }];

export default aivAthenaDeployRoutes;
