import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AivAthenaTest = Loadable(lazy(() => import('./aivAthenaTest')));

const aivAthenaTestRoutes = [
    { path: '/aivAthena/test', element: <AivAthenaTest />, auth: authRoles.admin }];

export default aivAthenaTestRoutes;
