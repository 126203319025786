import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AthenaDbProjects = Loadable(lazy(() => import('./AthenaDbProjects')));

const athenaDbProjectsRoutes = [
  { path: '/athena/db/projects', element: <AthenaDbProjects />, auth: authRoles.admin },
];

export default athenaDbProjectsRoutes;
