import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AdvancedPipeline = Loadable(lazy(() => import('./AdvancedPipeline')));

const advancedPipelineRoutes = [
  { path: '/advanced/pipeline', element: <AdvancedPipeline />, auth: authRoles.admin },
];

export default advancedPipelineRoutes;
