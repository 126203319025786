import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const BenchmarkCsv = Loadable(lazy(() => import('./BenchmarkCsv')));

const benchmarkCsvRoutes = [
  { path: '/benchmark/csv', element: <BenchmarkCsv />, auth: authRoles.admin },
];

export default benchmarkCsvRoutes;
