import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const BenchmarkDB = Loadable(lazy(() => import('./BenchmarkDB')));

const benchmarkDBRoutes = [
  { path: '/benchmark/db', element: <BenchmarkDB />, auth: authRoles.admin },
];

export default benchmarkDBRoutes;
