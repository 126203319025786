import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@material-ui/icons/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '& > *': {
      padding: '0px 5px', // Adjust the padding as desired
    },
  },
}));

const ExplainParametersTable = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parametersExplanationData = JSON.parse(searchParams.get('parametersExplanationData'));

  const classes = useStyles();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [editDescriptionKey, setEditDescriptionKey] = useState(null);
  const [editDescriptionValue, setEditDescriptionValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = () => {
    // Search logic here
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleEditDescription = (key, description) => {
    setEditDescriptionKey(key);
    setEditDescriptionValue(description);
  };

  const handleSaveDescription = (key) => {
    // Update the description value in the data source or perform any other necessary action
    parametersExplanationData[key].description = editDescriptionValue;
    setEditDescriptionKey(null);
  };

  const handleCancelDescription = () => {
    setEditDescriptionKey(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = Object.keys(parametersExplanationData).sort((a, b) => {
    if (parametersExplanationData[a][sortConfig.key] < parametersExplanationData[b][sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (parametersExplanationData[a][sortConfig.key] > parametersExplanationData[b][sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredData = sortedData.filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <div>
        <TextField
          size="small"
          label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <IconButton style={{ color: "#0018F9" }} onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
        <br />
        <br />
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#D6CFC7' }}>
              <TableCell align="center" style={{ width: '100px' }}>
                <TableSortLabel
                  active={sortConfig.key === 'key'}
                  direction={sortConfig.direction || 'asc'}
                  onClick={() => handleSort('key')}
                >
                  Key
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" style={{ width: '100px' }}>
                <TableSortLabel
                  active={sortConfig.key === 'type'}
                  direction={sortConfig.direction || 'asc'}
                  onClick={() => handleSort('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" style={{ width: '300px' }}>
                <TableSortLabel
                  active={sortConfig.key === 'description'}
                  direction={sortConfig.direction || 'asc'}
                  onClick={() => handleSort('description')}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" style={{ width: '100px' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((key) => (
              <TableRow className={classes.tableRow} style={{ backgroundColor: '#D9DDDC' }} key={key}>
                <TableCell align="center">{key}</TableCell>
                <TableCell align="center">{parametersExplanationData[key].type}</TableCell>
                <TableCell align="center">
                  {editDescriptionKey === key ? (
                    <>
                      <TextField
                        multiline
                        size="small"
                        value={editDescriptionValue}
                        onChange={(e) => setEditDescriptionValue(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      {parametersExplanationData[key].description}
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {editDescriptionKey === key ? (
                    <>
                      <IconButton style={{ color: "#0018F9" }} onClick={() => handleSaveDescription(key)}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton style={{ color: "#D30000" }} onClick={handleCancelDescription}>
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton style={{ color: "#01796F" }} onClick={() =>
                      handleEditDescription(key, parametersExplanationData[key].description)
                    }>
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ExplainParametersTable;
